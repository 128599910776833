import React, { useState } from 'react';
import {
    Hexagon, Download, Code,
    Settings, Brain, Workflow,
    TestTube, FileCode, LineChart,
    Blocks, GitBranch, Calendar,
    Users, Puzzle,
    Shield,
    Sparkles,
    Play
} from 'lucide-react';
import { FaApple, FaWindows, FaLinux } from 'react-icons/fa';
import InteractiveGridBackground from './InteractiveGridBackground';
import EnhancedDownloadSection from './EnhancedDownloadSection';
import FAQSection from './FAQSection';
import WaitlistForm from './WaitlistForm';
import VideoModal from './VideoModal';
import { getAnalytics, logEvent } from 'firebase/analytics';

export default function Home() {
    const [showCodeFlowAnimation, setShowCodeFlowAnimation] = useState(false);
    const [activeTab, setActiveTab] = useState('mac');
    const [showWaitlistForm, setShowWaitlistForm] = useState(false);
    const [showVideo, setShowVideo] = useState(false);

    const features = [
        { name: 'Code Flow', icon: Workflow, available: true, description: 'Advanced visual programming flow', onClick: () => setShowCodeFlowAnimation(true) },
        { name: 'Block Execution', icon: Blocks, available: true, description: 'Intuitive block-based coding' },
        { name: 'Documentation', icon: FileCode, available: true, description: 'Auto-generated documentation' },
        { name: 'Analytics', icon: LineChart, available: true, description: 'Real-time performance insights' },
        { name: 'Testing', icon: TestTube, available: true, description: 'Integrated testing ecosystem' },
        { name: 'AI Integration', icon: Brain, available: true, description: 'Smart code suggestions & analysis' },
        { name: 'Multi-File Flow', icon: GitBranch, available: false, description: 'Complex project visualization' },
        { name: 'Auto Generation', icon: Puzzle, available: false, description: 'AI-powered code generation' },
        { name: 'Priority System', icon: Calendar, available: false, description: 'Intelligent task prioritization' },
        { name: 'Collaboration', icon: Users, available: false, description: 'Real-time team collaboration' }
    ];

    const trackWaitlistEvent = (productType: string) => {
        try {
            const analytics = getAnalytics();
            logEvent(analytics, 'waitlist_join_clicked', {
                product_type: productType,
                timestamp: new Date().toISOString(),
                source: 'website',
                platform: navigator.platform,
                screen_size: `${window.innerWidth}x${window.innerHeight}`
            });
        } catch (error) {
            console.error('Error logging waitlist event:', error);
        }
    };

    const handleWaitlistClick = () => {
        trackWaitlistEvent("main");
        setShowWaitlistForm(true);
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 relative pb-24">
            <InteractiveGridBackground />

            {/* Navigation */}
            <nav className="fixed top-0 left-0 right-0 bg-gray-900/80 backdrop-blur-md z-50">
                <div className="container mx-auto px-4 md:px-6 py-4">
                    <div className="flex items-center justify-between">
                        {/* Logo */}
                        <div className="flex items-center gap-3">
                            <div className="group relative w-10 h-10">
                                <div className="absolute inset-0 bg-indigo-600/20 rounded-xl backdrop-blur-xl 
                                    border border-indigo-500/20 group-hover:border-indigo-500/40 
                                    transition-all duration-300" />
                                <div className="relative w-full h-full flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 124 124" fill="none">
                                        <rect width="124" height="124" rx="24" fill="#222556" />
                                        <path d="M19.375 36.7818V100.625C19.375 102.834 21.1659 104.625 23.375 104.625H87.2181C90.7818 104.625 92.5664 100.316 90.0466 97.7966L26.2034 33.9534C23.6836 31.4336 19.375 33.2182 19.375 36.7818Z" fill="#5f68bf" />
                                        <circle cx="63.2109" cy="37.5391" r="15.1641" fill="#5f68bf" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-white font-medium">Huffman</span>
                                <span className="text-gray-400 text-sm hidden sm:block">Visual Development Platform</span>
                            </div>
                        </div>

                        {/* Navigation Links */}
                        <div className="hidden md:flex items-center gap-6">
                            <a href="#features" className="text-gray-300 hover:text-white transition-colors">Features</a>
                            <a href="#download" className="text-gray-300 hover:text-white transition-colors">Download</a>
                            <a href="#pricing" className="text-gray-300 hover:text-white transition-colors">Pricing</a>
                            <a href="#docs" className="text-gray-300 hover:text-white transition-colors">Documentation</a>
                        </div>

                        {/* Mobile Menu Button */}
                        <button className="md:hidden p-2 text-gray-400 hover:text-white transition-colors">
                            <Settings size={20} />
                        </button>
                    </div>
                </div>
            </nav>

            {/* Main Content */}
            <main className="container mx-auto px-4 md:px-6 pt-24 space-y-24">
                {/* Hero Section */}
                <section className="text-center space-y-8 max-w-4xl mx-auto pt-12">
                    <div className="inline-block mb-4 px-4 py-2 bg-indigo-500/10 rounded-full 
                        border border-indigo-500/20">
                        <span className="text-indigo-400 text-sm font-medium">Revolutionize Your Development Workflow</span>
                    </div>
                    <h1 className="text-3xl md:text-5xl font-bold text-white leading-tight">
                        Visual Development Platform for Modern Developers
                    </h1>
                    <p className="text-lg md:text-xl text-gray-400 max-w-2xl mx-auto leading-relaxed">
                        Transform your coding experience with our innovative block-based approach.
                        Build faster, visualize better, ship with confidence.
                    </p>
                    <div className="flex justify-center gap-4">
                        <button
                            onClick={handleWaitlistClick}
                            className="group relative w-64 px-6 py-4 rounded-lg font-medium text-white
                            overflow-hidden transition-all duration-300 transform hover:-translate-y-1 hover:shadow-xl"
                        >
                            {/* Animated gradient background */}
                            <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 
                                animate-gradient-x transition-all duration-300 group-hover:opacity-90"></div>

                            {/* Shimmer effect */}
                            <div className="absolute inset-0 opacity-0 group-hover:opacity-20 
                                bg-gradient-to-r from-transparent via-white to-transparent 
                                translate-x-[-100%] group-hover:translate-x-[100%] 
                                transition-all duration-1000"></div>

                            {/* Button content with icon */}
                            <div className="relative flex items-center justify-center gap-2">
                                <Sparkles className="w-5 h-5 transition-transform duration-300 
                                    group-hover:rotate-12" />
                                <span className="font-semibold tracking-wide transition-all duration-300 
                                    group-hover:tracking-wider whitespace-nowrap">
                                    Join Waitlist
                                </span>
                                <div className="absolute right-0 transform translate-x-full opacity-0 
                                    group-hover:translate-x-0 group-hover:opacity-100 transition-all duration-300">
                                    →
                                </div>
                            </div>

                            {/* Animated border */}
                            <div className="absolute inset-0 border-2 border-white/20 rounded-lg 
                                transition-all duration-300 group-hover:border-white/40 
                                group-hover:scale-105"></div>
                        </button>

                        <button
                            onClick={() => setShowVideo(true)}
                            className="group relative px-6 py-4 rounded-lg font-medium text-white
                            overflow-hidden transition-all duration-300 transform hover:-translate-y-1 
                            bg-gray-800/50 hover:bg-gray-800/70 border border-gray-700/50
                            flex items-center gap-2"
                        >
                            <div className="relative flex items-center justify-center gap-2">
                                <Play className="w-5 h-5 text-indigo-400" />
                                <span>Watch Demo</span>
                            </div>
                        </button>
                    </div>

                    {/* Download Tabs */}
                    <EnhancedDownloadSection />

                    {showWaitlistForm && (
                        <div
                            className="fixed inset-0 bg-black/60 backdrop-blur-sm z-50 flex items-center justify-center p-4"
                            onClick={(e) => e.target === e.currentTarget && setShowWaitlistForm(false)}
                        >
                            <WaitlistForm onClose={() => setShowWaitlistForm(false)} />
                        </div>
                    )}

                </section>

                {/* Features Grid */}
                <section id="features" className="scroll-mt-24">
                    <div className="text-center max-w-3xl mx-auto mb-16">
                        <span className="text-indigo-400 font-medium">Features</span>
                        <h2 className="text-3xl font-bold text-white mt-2 mb-4">
                            Powerful Development Tools
                        </h2>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4">
                        {features.map((feature, index) => (
                            <div key={index}>
                                <div
                                    className="group relative bg-gray-800/50 backdrop-blur-xl rounded-lg 
                                        hover:-translate-y-1 transition-all duration-300 p-4 cursor-pointer"
                                    onClick={feature.onClick}
                                >
                                    <div className="absolute inset-0 rounded-lg bg-indigo-500/10 opacity-0 
                                        group-hover:opacity-100 transition-all duration-300 blur-sm" />
                                    <div className="relative flex flex-col items-center text-center">
                                        <feature.icon className={`w-6 h-6 text-indigo-400 mb-2 
                                            ${feature.onClick ? 'group-hover:animate-pulse' : ''}`} />
                                        <h3 className="text-sm font-medium text-white mb-1">{feature.name}</h3>
                                        <p className="text-xs text-gray-400">{feature.description}</p>
                                        {!feature.available && (
                                            <span className="absolute top-0 right-0 text-xs px-2 py-1 
                                                bg-indigo-500/20 text-indigo-400 rounded-full">
                                                Coming Soon
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>

                {/* Pricing Section */}
                <section id="pricing" className="scroll-mt-24">
                    {/* Pricing content remains the same */}
                </section>

                {/* FAQ Section */}
                <FAQSection />
            </main>

            {/* Footer */}
            <footer className="mt-24 border-t border-gray-800/30">
                {/* Footer content remains the same */}
            </footer>

            {/* Video Modal */}
            <VideoModal
                isOpen={showVideo}
                onClose={() => setShowVideo(false)}
                videoId="uEfmwSfDLgo"
            />
        </div>
    );
}